<template>
  <div>
    <span class="mgr-10">{{ item.name }}</span><span> {{ 'JWNETID:' + item.jwnetId }}</span>
  </div>
</template>

<script>
export default {
  props: {
    item: { required: true },
    searchText: { required: true }
  }
}
</script>
<style scoped>
.mgr-10{
  margin-right : 10px;
}
</style>
